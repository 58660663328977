<template>
  <div id="productInformationContainer" v-if="!isPermission">
    <!-- no access data -->
    <access-data></access-data>
  </div>
  <div id="productInformationWrapper" v-else>
    <!-- Show Loading indicator -->
    <div id="productInformationLoading" v-if="isLoadingProduct">
      <loading-page></loading-page>
    </div>

    <!-- Loaded data -->
    <form id="productInformationForm" v-else>
      <CCol id="productInformationCol" md="12" lg="12">
        <!-- Information -->

        <CAlert id="productInformationSuccessAlert" color="success" v-if="isSuccess === true">
          {{ $t("updateProductSuccess") }}
        </CAlert>

        <br />
        <CRow id="productInformationRow" md="12" sm="12" class="form-group row">
          <CCol id="productInformationImageLabelCol" md="2" sm="2" class="imagelabel">
            <label id="productInformationImageLabel" class="col-form-label text-right text-dark font-weight-normal">
              {{ $t("productImg") }}
            </label>
          </CCol>
          <CCol id="productInformationImageCol" md="4" sm="10" class="mb-3">
            <div id="productInformationImageContainer" v-if="imageData.length > 0" style="
                text-align: center;
                box-shadow: 0 0 0 1px #e5e5e5;
                border-radius: 0.2rem;
                width: 160px;
              ">
              <div id="productInformationProfileImg" class="profile-img doc-img">
                <div id="productInformationProfileWidget" class="profile-widget">
                  <div id="productInformationEditDataContainer" v-if="isEditData">
                    <img id="productInformationImage" :src="imageData" class="img-fluid" alt="product image"
                      @error="noImgUrl" @click="selectcolormodal" />
                  </div>
                  <div id="productInformationViewDataContainer" v-else>
                    <img id="productInformationImageNoEdit" :src="imageData" class="img-fluid" alt="product image"
                      @error="noImgUrl" />
                  </div>
                </div>
              </div>
            </div>
            <div id="productInformationImagePlaceholder" v-else style="
                text-align: center;
                box-shadow: 0 0 0 1px #e5e5e5;
                border-radius: 0.2rem;
                width: 160px;
              ">
              <div id="productInformationEditPlaceholder" v-if="isEditData">
                <CButton id="productInformationEditButton" :style="{ backgroundColor: colori }"
                  @click="selectcolormodal" style="width: 100%; height: 136px" class="img-fluid">
                  <CIcon>
                    <i class="fas fa-square"></i>
                  </CIcon>
                </CButton>
              </div>
              <div id="productInformationViewPlaceholder" v-else>
                <CButton id="productInformationViewButton" :style="{ backgroundColor: colori }"
                  style="width: 100%; height: 136px" class="img-fluid">
                  <CIcon>
                    <i class="fas fa-square"></i>
                  </CIcon>
                </CButton>
              </div>
            </div>
          </CCol>

          <!-- Additional Images -->
          <CCol id="productInformationAdditionalImageCol" v-if="isSelfOrderEnabled" md="6" class="mb-2">
            <CButton id="productInformationAddVideoButton" key="add-video" @click="selectVideo()" :style="{
              position: 'relative',
              width: '50px',
              height: '50px',
              margin: '2px',
              backgroundColor: currentVideo !== '' ? '#3abc98' : '#d8dbe0',
              color: '#878787',
              fontSize: 'medium',
              overflow: 'hidden',
            }" class="img-fluid">
              <!-- Video -->
              <div id="productInformationVideoIcon" v-if="currentVideo !== ''" style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                ">
                <i class="fi fi-rr-film"></i>
              </div>
              <div id="productInformationVideoPlaceholder" v-else style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <i class="fi fi-rr-film"></i>
              </div>
            </CButton>

            <CButton id="productInformationAdditionalImageButton" v-for="(index, i) in additionImageAmount - 1" :key="i"
              @click="selectAdditionalImg(i)" style="
                position: relative;
                width: 50px;
                height: 50px;
                margin: 2px;
                background-color: #d8dbe0;
                color: #878787;
                font-size: medium;
                overflow: hidden;
              " class="img-fluid">
              <!-- Image -->
              <img id="productInformationAdditionalImage" v-if="
                localAdditionImagePath[i] !== undefined &&
                localAdditionImagePath[i] !== null
              " @error="noImgUrl" :src="localAdditionImagePath[i]" style="
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  position: absolute;
                  top: 0;
                  left: 0;
                " />
              <!-- '+' placeholder -->
              <div id="productInformationImagePlaceholder" v-else style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                +
              </div>
            </CButton>
            <label id="productInformationAdditionalImageLabel" v-html="$t('additionalImage')"
              class="col-form-label text-dark mt-0 mb-0"></label>
            <p id="productInformationAdditionalImageNote" style="font-size: small" class="text-grey text-small mb-0">
              *{{ $t("useInQr") }}
            </p>
          </CCol>
        </CRow>
        <CModal id="productInformationUploadVideoModal" color="success" :show.sync="uploadVideoModal"
          :title="$t('uploadVideo')" centered>
          <CRow id="productInformationVideoOptionRow" style="justify-content: center" v-if="videoOption == ''">
            <CCol id="productInformationVideoUploadOption" md="5" sm="12" @click="videoOption = 'Upload'"
              class="text-center pt-5 pb-5 m-2" style="
                border-radius: 10px;
                border-style: dashed;
                border-color: #cfcfcf;
                border-width: 5px;
              ">
              <i class="fi fi-rr-upload" style="font-size: xx-large"></i> <br />
              <p class="mt-2">{{ $t("uploadVideo") }}</p>
            </CCol>
            <CCol id="productInformationVideoUrlOption" md="5" sm="12" @click="videoOption = 'URL'"
              class="text-center pt-5 pb-5 m-2" style="
                border-radius: 10px;
                border-style: dashed;
                border-color: #cfcfcf;
                border-width: 5px;
              ">
              <i class="fi fi-rr-play" style="font-size: xx-large"></i><br />
              <p class="mt-2">Youtube URL</p>
            </CCol>
          </CRow>

          <CRow id="productInformationVideoUploadRow" v-if="videoOption == 'Upload'">
            <label id="productInformationDeleteVideoLabel" v-if="currentVideoHolder !== ''"
              class="col text-right pr-4 mb-4" style="color: red" @click="delteVideo()">
              {{ $t("deleteVideo") }}
            </label>
            <video id="productInformationVideoPreview" v-if="currentVideoHolder !== ''"
              style="width: 100%; height: 100%" :src="currentVideoHolder" controls></video>

            <label id="productInformationVideoUploadLabel" class="col-12">
              <div id="productInformationVideoUploadContainer" v-if="currentVideoHolder == ''"
                class="text-center pt-5 pb-3" style="
                  border-radius: 10px;
                  border-style: dashed;
                  border-color: #cfcfcf;
                  border-width: 5px;
                ">
                <i class="fi fi-rr-upload" style="font-size: xx-large"></i>
                <br />
                <p class="mt-2">{{ $t("uploadVideo") }}</p>
                <p class="text-danger">*{{ $t("videoSizeNotTooBig") }}</p>
                <input id="productInformationVideoFileInput" class="pl-5" ref="video" type="file" accept="video/*"
                  @change="handleVideoFileChange($event.target)" />
              </div>
            </label>
          </CRow>

          <CRow id="productInformationVideoUrlRow" v-if="videoOption == 'URL'">
            <LazyYoutube id="productInformationLazyYoutube" v-if="currentVideoHolder !== ''"
              :src="currentVideoHolder" />
            <label id="productInformationPasteUrlLabel" class="col-12 mt-3">
              {{ $t("pasteURL") }}
              <small style="color: rgb(180, 180, 180)">*Ex. https://youtu.be/sRxrwjOtIag?si=3yfcN0XRXnsJlQxM</small>
            </label>
            <CCol id="productInformationVideoUrlInputCol" md="12" sm="12">
              <CInput id="productInformationVideoUrlInput" v-model="currentVideoHolder"></CInput>
            </CCol>
          </CRow>

          <template #footer-wrapper>
            <div></div>
            <CRow id="productInformationVideoModalFooter" class="p-3" v-if="videoOption !== ''">
              <CCol>
                <CButton id="productInformationSaveVideoButton" block color="success" v-if="loadingSaveimg === true"
                  v-on:click="saveVideo()">
                  {{ $t("save") }}
                </CButton>
                <CButton id="productInformationSaveVideoButtonDisabled" block color="success"
                  v-else-if="loadingSaveimg === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol>
                <CButton id="productInformationCancelVideoButton" block color="light" @click="delteVideo()">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
        <CModal id="productInformationAdditionalModal" color="success" :show.sync="additionalModal"
          :title="$t('selectImg')" centered>
          <div id="productInformationRow" class="row">
            <label id="productInformationSelectImgLabel" class="col-6 pl-3">{{ $t("selectImgFromPC") }}
              <input id="productInformationFileInput" ref="fileInput" class="pt-2" type="file" accept="image/*"
                @change="handleFileChange($event.target)" />
            </label>
            <label id="productInformationDeleteImgLabel" class="col-6 text-right pr-4" style="color: red"
              @click="deleteLocalImg()">
              <i class="fi fi-rr-trash"></i> {{ $t("deleteImg") }}
            </label>
          </div>
          <VueCropper id="productInformationCropper" v-if="
            currentImg !== '' &&
            currentImg !== null &&
            !currentImg.startsWith('https://firebasestorage')
          " :guides="true" :aspectRatio="1.3" :initialAspectRatio="1.3" ref="cropper2" :src="getCurrentImage()"
            alt="Source Image" :zoomable="false" :scalable="false"></VueCropper>
          <img id="productInformationImagePreview" v-else :src="getCurrentImage()" class="img-fluid" />
          <template #footer-wrapper>
            <CRow id="productInformationFooter" class="p-4">
              <CCol>
                <CButton id="productInformationSaveButton" block color="success" v-if="loadingSaveimg === true"
                  v-on:click="saveAdditionalImg">
                  {{ $t("save") }}
                </CButton>
                <CButton id="productInformationSaveButtonDisabled" block color="success"
                  v-else-if="loadingSaveimg === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol>
                <CButton id="productInformationCancelButton" block color="light" @click="additionalModal = false">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>

        <CRow id="productInformationProductDetailsRow">
          <CCol id="productInformationProductNameCol" md="12" class="form-group row">
            <label id="productInformationProductNameLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              <b class="text-danger font-weight-normal">*</b>
              {{ $t("productName") }}
            </label>
            <div class="col-sm-10">
              <CInput id="productInformationProductNameInput" v-model="SKUName" :placeholder="$t('enterProductName')"
                class="text-black" required />
            </div>
          </CCol>
        </CRow>
        <!-- Description -->
        <CRow id="productInformationDescriptionRow">
          <CCol id="productInformationDescriptionCol" md="12" class="form-group row">
            <label id="productInformationDescriptionLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              {{ $t("productDes") }}
            </label>
            <div class="col-sm-10">
              <textarea id="productInformationDescriptionTextarea" v-model="productDescription" class="form-control"
                rows="5" @input="validateDescription" :placeholder="$t('fillProductDetail')"></textarea>
              <small id="productInformationDescriptionCounter" v-if="productDescription.length < 60" class="text-grey">
                ({{ productDescription.length }}/5000)
              </small>
            </div>
          </CCol>
        </CRow>

        <CRow id="productInformationPriceRow">
          <CCol id="productInformationPriceCol" md="12" class="form-group row">
            <label id="productInformationPriceLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              {{ $t("price") }}
            </label>
            <div v-if="validateSKUPrice" class="col-sm-10">
              <CInput id="productInformationPriceInput" v-model.number="SKUPrice" :placeholder="$t('price')" min="0"
                type="number" :is-valid="SKUPrice !== null && SKUPrice !== '' && SKUPrice >= 0
                  " :is-invalid="SKUPrice === null || SKUPrice === ''"
                :invalid-feedback="$t('priceMustBeGreaterThanZero')" />
            </div>
            <div v-else class="col-sm-10" id="productInformationSKUPriceInputContainer">
              <CInput id="productInformationSKUPriceInput" v-model.number="SKUPrice" :placeholder="$t('price')" min="0"
                type="number" :is-valid="SKUPrice !== null && SKUPrice !== '' && SKUPrice >= 0
                  " :is-invalid="SKUPrice === null || SKUPrice === ''"
                :invalid-feedback="$t('priceMustBeGreaterThanZero')" />
            </div>
          </CCol>
          <CCol md="12" class="form-group row">
            <label id="productInformationCostLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              {{ $t("cost") }}
            </label>
            <div v-if="validateSKUPrice" class="col-sm-10" id="productInformationCostInputContainer">
              <input id="productInformationCostInput" min="0" v-model.number="stdCost" type="number" :class="[
                'form-control',
                'text-right',
                { 'text-danger': stdCost > SKUPrice && SKUPrice > 0 },
              ]" :disabled="skuType == 'BOM'" />
              <div id="productInformationCostWarningContainer" class="d-flex justify-content-end">
                <span id="productInformationCostWarning" v-if="stdCost > SKUPrice && SKUPrice > 0"
                  class="text-danger text-right" style="font-size: 10px">
                  * {{ $t("stdCostWarning") }}
                </span>
              </div>
            </div>
            <div v-else class="col-sm-10" id="productInformationCostInputContainerAlt">
              <CInput id="productInformationCostInputAlt" v-model.number="stdCost" :placeholder="$t('price')" min="0"
                type="number" :is-valid="stdCost !== null && stdCost !== '' && stdCost >= 0"
                :is-invalid="stdCost === null || stdCost === ''" :invalid-feedback="$t('priceMustBeGreaterThanZero')" />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12" class="form-group row">
            <label id="productInformationPluCodeLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              {{ $t("pluCode") }}
            </label>
            <div id="productInformationPluCodeInputContainer" class="col-sm-10">
              <CInput id="productInformationPluCodeInput" v-model="productcode" :placeholder="$t('pluCode')"
                :disabled="!isEditData" />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol md="12" class="form-group row">
            <label id="productInformationCategoryLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              <b class="text-danger">*</b> {{ $t("category") }}
            </label>
            <div id="productInformationCategoryInputContainer" class="col-sm-10">
              <multiselect id="productInformationCategorySelect" v-model="Category" :options="allCategories"
                :placeholder="$t('category')" label="name" track-by="name" v-if="isEditData"></multiselect>
              <input id="productInformationCategoryInput" v-else v-model="category.name" class="form-control"
                disabled />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol md="12" class="form-group row">
            <label id="productInformationUnitLabel"
              class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
              <b class="text-danger">*</b> {{ $t("unit") }}
            </label>
            <div id="productInformationUnitInputContainer" class="col-sm-10">
              <multiselect id="productInformationUnitSelect" v-model="unit" :options="unitlist" class="cursor"
                :placeholder="$t('unit')" label="name" track-by="name" v-if="isEditData"></multiselect>
              <input id="productInformationUnitInput" v-else v-model="unit.name" class="form-control" disabled />
            </div>
          </CCol>
        </CRow>
        <!-- Product Type Properties -->

        <div class="row mt-2" id="productTypeHeader">
          <div class="col-md-12 col-12">
            <h4 class="font-weight-normal text-dark" style="font-size: large">
              {{ $t("productType") }}
            </h4>
          </div>
        </div>

        <CCol lg="12" md="12" sm="12" class="row justify-content-center" id="productTypeCheckboxGroup">
          <CCol lg="8" md="6" sm="6" col="12">
            <CRow>
              <CCol sm="6" col="6" class="form-group">
                <div class="custom-control custom-checkbox" id="productTypeVatCheckbox">
                  <input type="checkbox" class="custom-control-input success cursor" v-model="vatType"
                    id="vatTypeCheckbox" true-value="V" false-value="N" :disabled="!isEditData" />
                  <label class="custom-control-label cursor" for="vatTypeCheckbox">
                    {{ $t("netTotalIncVAT") }}
                  </label>
                </div>
              </CCol>
              <CCol sm="6" col="6" class="form-group">
                <div class="custom-control custom-checkbox" id="productTypeFavoriteCheckbox">
                  <input type="checkbox" class="custom-control-input success cursor" v-model="favorite"
                    id="favoriteCheckbox" :disabled="!isEditData" />
                  <label class="custom-control-label cursor" for="favoriteCheckbox">
                    {{ $t("favorite") }}
                  </label>
                </div>
              </CCol>
              <CCol sm="6" col="6" class="form-group">
                <div class="custom-control custom-checkbox" id="productTypeServiceChargeCheckbox">
                  <input type="checkbox" class="custom-control-input success cursor" v-model="serviceCharge"
                    id="serviceChargeCheckbox" :disabled="!isEditData" />
                  <label class="custom-control-label cursor" for="serviceChargeCheckbox">
                    {{ $t("serviceCharge") }}
                  </label>
                </div>
              </CCol>
              <CCol sm="6" col="6" class="form-group">
                <div class="custom-control custom-checkbox" id="productTypeIsOnScreenCheckbox">
                  <input type="checkbox" class="custom-control-input success cursor" v-model="isOnScreen"
                    id="isOnScreenCheckbox" :disabled="!isEditData" />
                  <label class="custom-control-label cursor" for="isOnScreenCheckbox">
                    {{ $t("isOnScreen") }}
                  </label>
                </div>
              </CCol>
              <CCol sm="6" col="6" class="form-group">
                <div class="custom-control custom-checkbox" id="productTypeNegotiatePriceCheckbox">
                  <input type="checkbox" class="custom-control-input success cursor" v-model="negotiatePrice"
                    id="negotiatePriceCheckbox" :disabled="!isEditData" />
                  <label class="custom-control-label cursor" for="negotiatePriceCheckbox">
                    {{ $t("negotiatePrice") }}
                  </label>
                </div>
              </CCol>
              <CCol sm="6" col="6" class="form-group" v-if="isMenuEnabled">
                <div class="custom-control custom-checkbox" id="productTypeIsMenuCheckbox">
                  <input type="checkbox" class="custom-control-input success cursor" v-model="isMenu"
                    id="isMenuCheckbox" :disabled="!isEditData" />
                  <label class="custom-control-label cursor" for="isMenuCheckbox">
                    {{ $t("isMenu") }}
                  </label>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CCol>

        <CCol lg="12" md="12" sm="12">
          <div class="form-group row" id="productTypeSelectGroup">
            <label class="col-sm-2 col-4 col-form-label text-right text-dark mt-3" id="productTypeSelectLabel">
              <b class="text-danger">*</b> {{ $t("productType") }}
            </label>
            <div class="col-sm-5 col-8 mt-3">
              <select id="productTypeSelect" class="custom-select" v-model="skuType" disabled>
                <option value="P" selected>
                  {{ $t("productTypeP") }}
                </option>
                <option value="BOM">
                  {{ $t("productTypeBOM") }}
                </option>
                <option value="SN">
                  {{ $t("productTypeSN") }}
                </option>
                <option value="SV">
                  {{ $t("productTypeSV") }}
                </option>
              </select>
            </div>
          </div>
        </CCol>
      </CCol>
      <CRow id="colorSelectionModal">
        <CModal :closeOnBackdrop="false" color="success" :show.sync="selectcolor" :title="$t('selectImg')" centered>
          <div v-if="loadingSaveimg">
            <CRow id="colorButtons">
              <CCol md="1" sm="1" col="2" style="margin: 3px" v-for="item in colorList" :key="item.value"
                :value="item.value">
                <CButton v-if="indexColor === item.value" id="selectedColorButton" style="
                    box-shadow: 0 0 5px 5px rgb(163, 222, 194);
                    color: white;
                  " :class="{ active: indexColor === item.value }" @click="setindexcolor(item.value)"
                  :style="{ backgroundColor: item.color }">
                  <i class="fi fi-rr-check"></i>
                </CButton>
                <CButton v-else id="unselectedColorButton" :style="{ backgroundColor: item.color }"
                  :class="{ active: indexColor === item.value }" @click="setindexcolor(item.value)">
                  <CIcon>
                    <i class="fas fa-square fa-2x"></i>
                  </CIcon>
                </CButton>
              </CCol>
            </CRow>
            <hr />
            <div class="row" id="imageUploadSection">
              <div class="col-md-6 col-6 text-left">
                <label id="uploadImageLabel">{{ $t("selectImgFromPC") }}</label>
                <input id="imageUploadInput" type="file" ref="fileInput" accept="image/*" @change="uploadImage" />
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-6 col-6 text-right" id="deleteImageSection">
                <CButton style="color: red" @click="removeImage" id="deleteImageButton">
                  <i class="fi fi-rr-trash"></i> {{ $t("deleteImg") }}
                </CButton>
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-12 text-center" id="previewImageSection">
                <img v-if="isUpload" :src="imageData" alt="..." width="40%" @click="selectcolormodal" class="avatar"
                  id="uploadedImagePreview" />
                <VueCropper v-if="
                  !isUpload &&
                  !imageDataBtn.startsWith('https://firebasestorage')
                " v-show="imageDataBtn" :guides="true" :aspectRatio="1.3" :initialAspectRatio="1.3" ref="cropper"
                  :src="imageDataBtn" alt="Source Image" :zoomable="false" :scalable="false" id="imageCropper">
                </VueCropper>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center" width="100%">
              <button class="btn" type="button" disabled style="color: darkcyan" id="loadingImageButton">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ $t("กำลังอัปโหลดรูปภาพ...") }}
              </button>
            </div>
          </div>

          <template #footer-wrapper>
            <CRow class="p-4" id="modalFooterButtons">
              <CCol>
                <CButton block color="success" v-if="loadingSaveimg" @click="savecolorimg" id="saveColorButton">
                  {{ $t("save") }}
                </CButton>
                <CButton block color="success" v-else disabled id="saveColorButtonDisabled">
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol>
                <CButton block color="light" @click="cancel" id="cancelColorSelection">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>

      <CRow id="validationModalRow">
        <CModal color="warning" :show.sync="validationModal" :title="$t('dialogfound')" centered id="validationModal">
          <CRow>
            <CCol class="text-center mt-2" style="font-size: 24px" id="validationMessage">
              <p v-if="validateSKUName" class="text-warning">
                {{ validateSKUName }}
              </p>
              <p v-if="validateSKUPrice" class="text-warning">
                {{ validateSKUPrice }}
              </p>
            </CCol>
          </CRow>
          <template #footer-wrapper>
            <div class="text-center mb-3" id="validationModalFooter">
              <CButton color="warning" @click="validationModal = false" class="text-white" style="width: 30%"
                id="closeValidationModalButton">
                {{ $t("submit") }}
              </CButton>
            </div>
          </template>
        </CModal>
      </CRow>

      <CModal :show.sync="alertModal" color="danger" :title="namemodal" centered id="alertModal">
        <div id="alertModalContent">
          <br />
          <h4 class="text-center" id="alertModalErrorMessage">
            {{ textError }}
          </h4>
          <br />
        </div>

        <template #footer>
          <CRow class="row justify-content-end" id="alertModalFooter">
            <CButton color="light" col="6" lg="4" md="4" block @click="alertModal = false" id="closeAlertModalButton">
              {{ $t("Close") }}
            </CButton>
          </CRow>
        </template>
      </CModal>

      <modal color="success" :show.sync="confirmModal" :title="$t('confirmSaveImg')" v-on:isSave="savedata" centered
        :alertError="alertError" :loading="loading" id="confirmSaveModal">
        <div v-if="alertError" id="confirmSaveModalErrorSection">
          <h4 class="text-center text-danger" id="confirmSaveModalErrorMessage">
            {{ textError }}
          </h4>
          <br />
          <h5 class="text-center text-danger" id="confirmSaveModalErrorDetails">
            {{ textMessage }}
          </h5>
        </div>
        <div v-else id="confirmSaveModalContent">
          <br />
          <h4 class="text-center" id="confirmSaveModalDialogMessage">
            {{ $t("confirmSaveImgDialog") }}
          </h4>
          <br />
          <div v-if="loading == false" id="confirmSaveModalProgressSection">
            <CProgress class="mb-3" id="confirmSaveProgress">
              <CProgressBar color="success" variant="striped" animated :value="uploadProgress"
                id="confirmSaveProgressBar" />
            </CProgress>
            <p class="text-center" id="confirmSaveProgressText">
              {{ onUploadText }}
            </p>
          </div>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import util from "@/util/util";
import { mapGetters } from "vuex";
import pos from "@/services/local";
import Multiselect from "vue-multiselect";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import permis from "@/util/permission";
import uploadImage from "@/util/uploadImage";
import mime from "@/util/mime";
import { LazyYoutube } from "vue-lazytube";

export default {
  props: {
    saveProductDetail: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Multiselect,
    VueCropper,
    LazyYoutube,
  },
  data() {
    return {
      selectedFileName: "",
      selected: false,
      rows: [],
      SKU: [],
      indexColor: 1,
      unitAdd: [],
      namemodal: "",
      price: 0,
      serviceCharge: "",
      vatType: "",
      favorite: "",
      isOnScreen: "",
      amount: 1,
      SKUPrice: 0,
      SKUName: "",
      productcode: "",
      stdCost: 0,
      previewImage: null,
      imageData: "",
      imageName: "",
      imagePath: "",
      skuType: "",
      productPRU: false,
      name: "",
      detail: [],
      countSKU: 0,
      unit: [],
      dataUnit: [],
      Category: [],
      isSuccess: false,
      selectcolor: false,
      imageDataBtn: "",
      categories: [],
      confirmModal: false,
      colori: util.generateColor(1),
      shop: this.$store.getters.shopObjectId,
      unitSelectcolor: false,
      pru: {
        exceedUc: 0,
        packQty: 0,
        packUc: 0,
        stdCost: 0,
      },
      deleteModal: false,
      loading: true,
      loadingSaveimg: true,
      negotiatePrice: "",
      isUpload: true,
      isload: false,
      popupModal: false,
      validateunit: "",
      validateSKUName: "",
      validateSKUPrice: "",
      validateproductcode: "",
      validatecategory: "",
      validateShop: "",
      validateAlert: "",
      textError: "",
      textMessage: "",
      alertError: false,
      isError: 0,
      isMenu: false,
      alertModal: false,
      imgTmpPath: "",
      alertValidatePRU: "",
      loadingButtonValidatePRU: false,
      resizedImageData: "",
      isLoadingProduct: true,
      additionImageAmount: parseInt(process.env.VUE_APP_ADDITIONAL_IMAGE),
      localAdditionImagePath: new Array(this.additionImageAmount),
      additionalModal: false,
      currentImg: "",
      currentButton: 0,
      trackingOldImagePath: [],
      additionImagePath: new Array(this.additionImageAmount),
      uploadVideoModal: false,
      videoOption: "",
      currentVideo: "",
      currentVideoHolder: "",
      maxFileSize: 20971520, //byte
      uploadProgress: 0,
      onUploadText: "",
      trackingOldVideoPath: "",
      trackingFirebaseItem: [],
      validationModal: false,
      productDescription: "",
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    validateDescription() {
      if (this.productDescription.length < 60) {
        return true;
      } else {
        return false;
      }
    },
    isSelfOrderEnabled() {
      return permis.findPermissionRead("selfOrder", this.$route.path);
    },
    isPermission() {
      let path = "/product";
      return permis.findPermissionRead("product", path);
    },
    isEditData() {
      let path = "/product";
      const value = permis.findPermissionEdit("product", path);
      return value; //&& this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.editableItem || false;
    },
    isDeleteData() {
      let path = "/product";
      return permis.findPermissionRemove("product", path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    productSKUObjectId() {
      return this.$route.params.productSKUObjectId;
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    colorList() {
      let data = [];
      for (let i = 0; i < 30; i++) {
        data.push({
          color: util.generateColor(i),
          value: i,
        });
      }
      return data;
    },
    exceedUCtotal: {
      get() {
        let data = this.pru.packUc / this.pru.packQty;
        return data;
      },
      set() {
        let data = this.pru.packUc / this.pru.packQty;
        return data;
      },
    },
    packUctotal() {
      let data = this.pru.packUc * this.pru.packQty;
      return data;
    },
    allCategories() {
      const data = this.categories || [];
      let objects = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        objects.push({
          id: item.id,
          name: item.name,
          objectId: item.objectId,
        });
      }
      return objects;
    },
    unitlist() {
      let data = this.dataUnit;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          });
        }
      }
      return detail.sort(util.compare);
    },
    rowslist() {
      let data = this.rows;
      let detail = [];
      let cost = parseFloat(this.stdCost);
      let stdCost = typeof cost == "number" ? parseFloat(cost) : 0;
      if (data.length > 0) {
        data.forEach((e) => {
          detail.push({
            id: e.id,
            objectId: e.objectId,
            packQty: e.packQty,
            price: e.packUc,
            packUc: util.convertCurrency(e.packUc),
            exceedUc: util.convertCurrency(e.exceedUc),
            stdCost:
              typeof stdCost == "number"
                ? (stdCost * e.packQty).toFixed(2)
                : stdCost,
            skuType: e.skuType,
          });
        });
      } else {
        detail.push({
          id: "",
          objectId: this.productSKUObjectId,
          packQty: 1,
          exceedUc: util.convertCurrency(this.SKUPrice),
          stdCost: typeof stdCost == "number" ? stdCost.toFixed(2) : stdCost,
          price: this.SKUPrice,
          packUc: this.SKUPrice,
          skuType: this.skuType,
        });
      }
      return detail;
    },
    showView() {
      return this.$route.query.view || "grid";
    },
    sortdata() {
      return this.$route.query.sort || "SKUName";
    },
    page() {
      return this.$route.query.page || 1;
    },
    searchBy() {
      return this.$route.query.searchBy || "";
    },
    keyword() {
      return this.$route.query.keyword || "";
    },
    queryURL() {
      return (
        "?sort=" +
        this.sortdata +
        "&page=" +
        this.page +
        "&view=" +
        this.showView +
        "&searchBy=" +
        this.searchBy +
        "&keyword=" +
        this.keyword
      );
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isSelfOrderEnabled() {
      const shop = this.shop || {};
      const delivery = shop.delivery || {};
      const enabled = delivery.isSelfOrderEnabled || false;
      return enabled;
    },
  },
  async created() {
    await this.getProduct();
    await this.getCategory();
    await this.getUnit();
    this.shop = this.shops.find((i) => i.objectId === this.shop);
  },
  mounted() {
    document.addEventListener("keydown", this.doSave);
  },
  watch: {
    saveProductDetail(newValue) {
      if (newValue) {
        this.validatePopup();
      }
    },
    SKUPrice(newValue) {
      if (this.rowslist[0].price !== newValue) {
        this.rowslist[0].price = newValue;
      }
    },
  },
  methods: {
    validator(val) {
      return val ? val.length >= 1 : false;
    },
    selectVideo() {
      this.uploadVideoModal = true;
    },
    selectAdditionalImg(index) {
      this.currentButton = index;
      if (this.localAdditionImagePath[index] !== undefined) {
        this.currentImg = this.localAdditionImagePath[index];
      } else {
        this.currentImg = "";
      }

      this.additionalModal = true;
    },
    pickFile() {
      this.$refs.image.click();
    },
    async saveAdditionalImg() {
      let cropedImage = this.$refs.cropper2.getCroppedCanvas().toDataURL();
      const resizedImage = await this.resizeImage(cropedImage);
      if (this.localAdditionImagePath.length <= this.additionImageAmount + 1) {
        this.localAdditionImagePath[this.currentButton] = resizedImage;
      } else {
        alert("Out of index");
      }
      this.$refs.cropper2.destroy();
      this.currentImg = "";
      this.additionalModal = false;
    },
    async getImageURL2() {
      try {
        for (let i = 0; i <= this.additionImageAmount; i++) {
          if (
            this.localAdditionImagePath[i] &&
            !this.localAdditionImagePath[i].startsWith(
              "https://firebasestorage"
            )
          ) {
            let ext = mime.getExtension(this.localAdditionImagePath[i]);
            if (ext == null) {
              this.isload = false;
            } else {
              const imageName = util.generateFilename("." + ext);
              const ownerUID = localStorage.getItem("uid");
              let ref = this.productSKUObjectId;
              const directoryPath =
                "resources/" +
                ownerUID +
                "/resources/products/" +
                ref +
                "/" +
                imageName;
              this.onUploadText = this.$i18n.t("imageUploading");
              this.uploadProgress = 66;
              const uploadedImagePath = await uploadImage.onUpload2(
                directoryPath,
                this.localAdditionImagePath[i]
              );
              this.additionImagePath.push(uploadedImagePath);
            }
          } else if (this.localAdditionImagePath[i] != null) {
            this.uploadProgress = 66;
            this.additionImagePath.push(this.localAdditionImagePath[i]);
          }
        }
      } catch (error) {
        console.error("Error during getImageURL2:", error);
      }
    },

    getCurrentImage() {
      return this.currentImg;
    },
    saveVideo() {
      this.currentVideo = this.currentVideoHolder;
      this.uploadVideoModal = false;
    },
    delteVideo() {
      this.videoOption = "";
      this.currentVideo = "";
      this.currentVideoHolder = "";
    },

    async blobToDataURL(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async uploadVideoToFirebase() {
      const ownerUID = `${localStorage.getItem("uid")}`;

      if (this.videoOption == "Upload") {
        this.onUploadText = this.$i18n.t("videoUploading");
        this.uploadProgress = 30;
        let videoDataUrl = await this.blobToDataURL(this.currentVideoHolder);
        let upload = await uploadImage.onUpload2(
          "resources/" +
          ownerUID +
          "/resources/products/" +
          this.productSKUObjectId +
          "/video001",
          videoDataUrl
        );
        this.currentVideo = upload;
      } else {
        const uid = `${localStorage.getItem("shopsUid")}`;
        const shopObjectId = this.shopObjectId;
        await uploadImage.deleteImage(
          uid,
          shopObjectId,
          this.trackingOldVideoPath
        );
      }
    },
    deleteLocalImg() {
      this.trackingOldImagePath.push(
        this.localAdditionImagePath[this.currentButton]
      );
      this.localAdditionImagePath[this.currentButton] = null;
      this.$refs.cropper2.destroy();
      this.currentImg = "";
    },
    handleVideoFileChange: function (event) {
      this.currentVideoHolder = "";
      let file = event.files[0];

      if (file.size <= this.maxFileSize) {
        this.$nextTick(() => {
          this.currentVideoHolder = window.URL.createObjectURL(file);
        });
      } else {
        event.target = "";
        alert(this.$i18n.t("bigVideoAlert"));
      }
    },
    handleFileChange: function (event) {
      this.isUpload = true;
      this.currentImg = "";
      let file = event.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        this.currentImg = e.target.result;
        this.$refs.cropper2 = this.currentImg;
        this.isUpload = false;
        this.$refs.fileInput.value = "";
      };
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE;
    },
    goback() {
      this.$router.push("/product" + this.queryURL);
    },
    inputSKUPrice(value) {
      this.rowslist[0].price = util.currencyStringToNumber(value);
      this.rowslist[0].exceedUc = util.convertCurrency(value);
    },
    inputpackUc(value) {
      this.SKUPrice = util.currencyStringToNumber(value);
      this.rowslist[0].exceedUc = util.convertCurrency(value);
    },
    doSave(e) {
      if (this.$router.app._route.name == "ProductDetail") {
        if (e.key === "Enter" && e.target.nodeName === "INPUT") {
          //check keycode enter จาก input form
          var focusableElementsString =
            'a[href], area[href], input:not([disabled]),select ,select:not([disabled]), textarea:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
          let arr = Array.from(
            e.target.form.querySelectorAll(focusableElementsString)
          ); // เก็บ input ทะ้งหมดที่อยู่ใน from เป็น array
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === e.target) {
              let indexOf = i < arr.length - 1 ? arr[i + 1] : indexOf[0]; //array จาก form input
              indexOf.focus();
              break; //ไปที่ input ลำดับต่อไป
            }
          }
          e.preventDefault();
        }
        if (!(e.keyCode === 83 && e.ctrlKey)) {
          return;
        } else if (e.keyCode === 83 && e.ctrlKey) {
          this.validatePopup();
        }
        e.preventDefault();
      }
    },
    checkExceed(event) {
      this.pru.packUc = event.target.value * this.pru.packQty;
    },
    addRow() {
      this.rows.push({});
    },
    setindexcolor(index) {
      this.indexColor = index;
      this.colori = util.generateColor(index);
      this.imageDataBtn = "";
      this.selectcolor = false;
    },
    async removeImage() {
      this.imageData = "";
      this.imageDataBtn = "";
      this.remoteImagePath = "";
      this.isload = false;
      this.selectcolor = false;
    },
    selectcolormodal() {
      this.isUpload = true;
      this.selectcolor = true;
    },
    cancel() {
      if (this.remoteImagePath != undefined) {
        this.imageDataBtn = this.remoteImagePath;
        this.selectcolor = false;
        this.isUpload = true;
        this.imageData = this.remoteImagePath;
      }
      this.selectcolor = false;
      this.isload = false;
    },
    cancelUpdate() {
      if (this.imgTmpPath != "") {
        this.removeImage();
      }
      this.$router.push("/product" + this.queryURL);
    },
    pickFile() {
      this.$refs.image.click();
    },
    savecolorimg() {
      this.loadingSaveimg = false;
      if (this.isload) {
        let cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.imageData = cropedImage;
        this.getImageURL();
      } else {
        this.imageData = "";
        this.remoteImagePath = "";
        this.selectcolor = false;
        this.loadingSaveimg = true;
      }
    },
    async getImageURL() {
      let ref = this.productSKUObjectId;
      if (this.$refs.cropper.getCroppedCanvas() === undefined) {
        return;
      }

      const getCroppedCanvas = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      const resizedImage = await this.resizeImage(getCroppedCanvas);
      let ext = await mime.getExtension(resizedImage);
      if (ext == null) {
        alert("Only Support File Type Is JPEG, PNG");
        this.isload = false;
      } else {
        const oldImagePath = this.imagePath;
        await uploadImage.deleteImage2(oldImagePath);

        const imageName = util.generateFilename("." + ext);
        const ownerUID = `${localStorage.getItem("uid")}`;
        const directoryPath =
          "resources/" +
          ownerUID +
          "/resources/products/" +
          ref +
          "/" +
          imageName;

        // this.remoteImagePath = await uploadImage.onUpload(ownerUID, ref, resizedImage, ext)
        this.remoteImagePath = await uploadImage.onUpload2(
          directoryPath,
          resizedImage
        );

        this.imgTmpPath = this.remoteImagePath;
        this.imagePath = directoryPath;
        this.selectcolor = false;
        this.loadingSaveimg = true;
      }
    },
    uploadImage(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        this.dialog = true;

        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageDataBtn = event.target.result;
          this.$refs.cropper = this.imageData;
          this.isUpload = false;
          this.isload = true;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async resizeImage(base64Str, maxWidth = 650, maxHeight = 500) {
      return new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          const MAX_WIDTH = maxWidth;
          const MAX_HEIGHT = maxHeight;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL());
        };
      });
    },
    validatePopup() {
      this.validateSKUName = "";
      this.validateSKUPrice = "";
      this.validateAlert = "";
      if (this.SKUName === "") {
        this.validateAlert = this.$i18n.t("enterProduct");
        this.validateSKUName = this.$i18n.t("enterProduct");
        this.validationModal = true;
        return;
      } else {
        this.validateSKUName = "";
      }
      if (this.SKUPrice < 0 || this.SKUPrice === "") {
        this.validateAlert = this.$i18n.t("priceMustBeGreaterThanZero");
        this.validateSKUPrice = this.$i18n.t("priceMustBeGreaterThanZero");
        this.validationModal = true;
        return;
      } else {
        this.validateSKUPrice = "";
      }
      this.validateAlert = "";

      if (this.productcode == "") {
        this.productcode = util.generateBarcode();
      }

      if (this.Category == "") {
        this.validateAlert = this.$i18n.t("selectCategory");
        this.validationModal = true;
        return;
      }
      if (this.unit == "" || this.unit === undefined) {
        this.validateAlert = this.$i18n.t("selectUnit");
        this.validationModal = true;
        return;
      }

      if (this.SKUPrice !== this.rowslist[0].price) {
        this.validateSKUPrice = this.$i18n.t("priceMustBeGreaterThanZero");
        this.validationModal = true;
        return;
      }

      this.alertError = false;
      this.loading = true;
      this.confirmModal = true;
    },
    async getUnit() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = { page: 1, limit: 1000 };
      const shopObjectId = this.shopObjectId;
      const headers = {
        shopObjectId: shopObjectId,
      };
      try {
        const URL = "/api/v1.0/uid/" + uid + "/Unit/data";
        const res = await pos.get(URL, { params, headers });
        this.dataUnit = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCategory() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = { page: 1, limit: 1000 };
      const headers = {
        shopObjectId: shopObjectId,
      };
      try {
        const URL = "/api/v1.0/shop/" + uid + "/Category/data/" + shopObjectId;
        const res = await pos.get(URL, { params, headers });
        this.categories = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getProduct() {
      this.loading = false;
      this.isLoadingProduct = true;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: "",
        objectId: this.productSKUObjectId,
      };

      try {
        const headers = { shopObjectId: shopObjectId };
        const res = await pos({
          url: "/api/v1.0/" + uid + "/productsku/getwithcategory/",
          params: params,
          headers: headers,
          method: "GET",
        });

        const data = res.data.data;

        if (data.remoteVideoPath != undefined && data.remoteVideoPath != "") {
          this.trackingFirebaseItem.push(data.remoteVideoPath);
          this.trackingOldVideoPath = data.remoteVideoPath;
          this.currentVideo = data.remoteVideoPath;
          this.currentVideoHolder = data.remoteVideoPath;
          if (this.currentVideoHolder.startsWith("https://firebasestorage")) {
            this.videoOption = "Upload";
          } else {
            this.videoOption = "URL";
          }
        }

        this.data = data;
        this.isLoadingProduct = false;
        if (data.additionImagePath != undefined) {
          this.localAdditionImagePath = data.additionImagePath;
          data.additionImagePath.forEach((element) => {
            this.trackingFirebaseItem.push(element);
          });
        }
        this.SKU = this.data;
        this.SKUName = data.SKUName;
        this.SKUPrice = parseFloat(data.SKUPrice);
        if (data.ProductPLU == undefined) {
          this.productcode = "";
        } else {
          this.productcode = data.ProductPLU.PLUCode;
        }
        this.indexColor = data.indexColor;
        this.isOnScreen = data.isOnScreen;
        this.favorite = data.favorite;
        this.vatType = data.vatType;
        this.serviceCharge = data.serviceCharge;
        this.skuType = data.skuType;
        this.imagePath = data.imagePath;
        if (data.stdCost == undefined) {
          this.stdCost = 0;
        } else {
          this.stdCost = parseFloat(data.stdCost).toFixed(2);
        }
        this.Category = data.Category;
        this.unit = data.unit;
        this.colori = util.generateColor(data.indexColor);
        if (
          this.data.remoteImagePath != undefined &&
          this.data.remoteImagePath != ""
        ) {
          this.trackingFirebaseItem.push(data.remoteImagePath);
          this.imageData = data.remoteImagePath;
          this.imageDataBtn = data.remoteImagePath;
          this.remoteImagePath = data.remoteImagePath;
          this.$refs.cropper = data.remoteImagePath;
        }
        this.negotiatePrice = data.negotiatePrice;

        if (this.data.isMenu !== null) {
          this.isMenu = data.isMenu;
        }

        if (data.description !== undefined) {
          this.productDescription = data.description.replace(/\\n/g, "\n");
        }

        this.loading = true;
      } catch (error) {
        console.log(error);
      }
    },
    async savedata() {
      this.loading = false;
      if (!this.currentVideo.startsWith("https://firebasestorage")) {
        await this.uploadVideoToFirebase();
      }

      let category = {
        id: this.Category.id,
        objectId: this.Category.objectId,
        name: this.Category.name,
      };
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const plan = this.users.currentPlan;

      this.additionImagePath.shift();
      await this.getImageURL2();
      let data = [];
      if (this.imageData == "") {
        const oldImagePath = this.imagePath;
        this.imagePath = "";
        uploadImage.deleteImage2(oldImagePath);
      }

      //Delete Additional Image
      this.trackingOldImagePath.forEach((element) => {
        let deleteImg = uploadImage.deleteImage(uid, shopObjectId, element);
      });

      if (this.remoteImagePath != "") {
        data = {
          objectId: this.productSKUObjectId,
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          category: category,
          categoryId: this.Category.id,
          enabled: true,
          favorite: this.favorite,
          indexColor: this.indexColor,
          isOnScreen: this.isOnScreen,
          productPLU: { PLUCode: this.productcode },
          serviceCharge: this.serviceCharge,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          skuType: this.skuType,
          stdCost: parseFloat(this.stdCost),
          unit: {
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          remoteImagePath: this.remoteImagePath,
          imagePath: this.imagePath,
          vatType: this.vatType,
          negotiatePrice: this.negotiatePrice,
          description: this.productDescription,
          additionImagePath: this.additionImagePath,
          remoteVideoPath: this.currentVideo,
        };
      } else {
        data = {
          objectId: this.productSKUObjectId,
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          category: {
            id: this.Category.id,
            name: this.Category.name,
            objectId: this.Category.objectId,
          },
          categoryId: this.Category.id,
          enabled: true,
          favorite: this.favorite,
          indexColor: this.indexColor,
          isOnScreen: this.isOnScreen,
          productPLU: { PLUCode: this.productcode },
          serviceCharge: this.serviceCharge,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          skuType: this.skuType,
          stdCost: parseFloat(this.stdCost),
          unit: {
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          vatType: this.vatType,
          remoteImagePath: "",
          imagePath: this.imagePath,
          negotiatePrice: this.negotiatePrice,
          description: this.productDescription,
          additionImagePath: this.additionImagePath,
          remoteVideoPath: this.currentVideo,
        };
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu });
      }
      const headers = { shopObjectId: shopObjectId };

      this.uploadProgress = 90;
      this.onUploadText = this.$i18n.t("productDataSaving");
      pos({
        method: "post",
        url: "/api/v1.0/" + uid + "/productsku/edit",
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then((response) => {
          setTimeout(() => {
            if (response.data.error.code === 4000) {
              this.textError = this.$i18n.t("failedToSave");
              this.textMessage = response.data.error.message;
              this.alertError = true;
              this.loading = true;
            } else {
              this.loading = true;
              this.confirmModal = false;
              this.isSuccess = true;

              // this.$router.push('/product' + this.queryURL)
            }
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addProductPLU() {
      this.$router.push("plu/" + this.productSKUObjectId + this.queryURL);
    },
    addProductBOM() {
      this.$router.push("bom/" + this.productSKUObjectId + this.queryURL);
    },
    addProductSerial() {
      this.$router.push("serial/" + this.productSKUObjectId + this.queryURL);
    },
    onclickChannels() {
      this.$router.push("channels/" + this.productSKUObjectId + this.queryURL);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.btn-add {
  border-color: #29b289;
  text-align: center;
  width: -webkit-fill-available;
  color: #29b289;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #29b289;
  background-color: #29b289;
}

.form-control {
  cursor: pointer;
}

.custom-select {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.multiselect__tags {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}

.multiselect__single {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  font-size: 14px;
  cursor: pointer;
}

.button-silom {
  left: 0;
  bottom: 0;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 5%);
}

@media screen and (min-width: 568px) {
  .imagelabel {
    text-align: right;
  }
}

@media screen and (max-width: 568px) {
  .col-form-label {
    text-align: left !important;
    margin-bottom: 8px;
  }
}
</style>

<style src="../../../assets/styles/imgProduct.css"></style>
